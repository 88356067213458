import React, { useState , useEffect, useRef} from 'react';
// import emailjs from 'emailjs-com';
import HeroPages from "../components/HeroPages";
import emailjs from '@emailjs/browser';

function EmailJs() {
    const textareaStyle = {
        resize: 'none', // Disable textarea auto-expansion
      };

    const [dropOff, setDropOff] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [numberOfDays, setNumberOfDays] = useState(null); // Store the calculated number of days
    const [validationError, setValidationError] = useState(""); // Store validation error message
    const [carmodel, setCarmodel] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);

        const currentUrl = window.location.href;// Get the current URL
        const match = currentUrl.match(/carmodel=([^&]+)/);// Use a regular expression to extract the value after "carmodel="
        if (match) {
            //setCarmodel(match[1]);// Extracted value is in match[1]
            setCarmodel(`Suzuki ${match[1]}`);
        } else {
            setCarmodel('Please select a car first.');// If "carmodel" is not found in the URL, set a default value or handle it accordingly
           //carmodel = `<option>Suzuki Jimny</option> <option>Suzuki S Presso</option> <option>Suzuki Ertiga</option>`;
        }
    }, []);

    const handleDropOffChange = (e) => {
        setDropOff(e.target.value);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    useEffect(() => {
        // Calculate the number of days when start date or end date changes
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const differenceInTime = end.getTime() - start.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            setNumberOfDays(differenceInDays);
        } else {
            setNumberOfDays(null); // Reset the number of days if dates are not set
        }
    }, [startDate, endDate]);


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        var element_no_days = document.getElementById('NumDays');
        var text_no_days = element_no_days.textContent;
        console.log("text_no_days: "+text_no_days)

        var element_car = document.getElementById('vehicle');
        var text_car = element_car.textContent;
        console.log(text_car)
        
        setNumberOfDays("hang tight...")


        if (text_no_days>=2 && text_car!== 'Please select a car first.'){
            emailjs.sendForm('service_z7azkju', 'template_obts3bc', form.current, '_TIhi7Hy0-fH0uiKW') //serice key, template, public key //PROD
            //emailjs.sendForm('service_d8lkere', 'template_so977ys', form.current, 'xmrtpu2akp-4NE2KQ') //serice key, template, public key //TEST
            .then((result) => {
            // console.log(result.text);
            alert("Request sent. Someone will get in touch shortly.")
            setNumberOfDays("Request sent. Someone will get in touch shortly.")
            }, (error) => {
                //  console.log(error.text);
                alert("Something went wrong. Please call on +230 52548614!")
                setNumberOfDays("Something went wrong. Please call on +230 52548614!")
            });
        // console.log("Sending Email")
        }
        else{
            alert("Either minimum of 2 days was not satisfied or Car was not selected!");
            setNumberOfDays("Either minimum of 2 days was not satisfied or Car was not selected!")
        }
    };

  return (
    <>
        <HeroPages name="Booking" />

        <section id="reg-section" className="reg-section">
            <div className="container">
            {/* <div className="book-content"> */}
            <div className="book-content__box">
                {/* <form action="https://formsubmit.co/cdf38da4bc8a07f3d553b97b5a3580c9" method="POST"> */}
                <form ref={form} onSubmit={sendEmail}>
                <div className="box-form__car-type">        
                    <h2>
                        <i>Personal information</i>
                        </h2> 

                    <input className="reg-div_text" type = "text" name="fullname" placeholder = "Full Name" required />
                    <input className="reg-div_text" type = "email" name="email_address"  placeholder = "Email Address" required />
                    <input className="reg-div_text" type = "phone" name="phone" placeholder = "Phone Number"  required />


                    <h2>
                    <i>Vehicle information</i>
                        </h2> 

                        <label>
                            Selected Vehicle <b>*</b>
                        </label>
                        <textarea rows="1" style={textareaStyle} value={carmodel}  className="reg-div_text" type = "text" name="vehicle" id="vehicle"/>  
                    
                        <label>
                            Vehicle Drop-off Location <b>*</b>
                        </label>
                        <select value={dropOff} onChange={handleDropOffChange} name="dropoff" required>
                            <option>Trou D'eau Douce</option>
                            <option>Flacq</option>
                            <option>Grand Gaube</option>
                            <option>Grand Bay</option>
                            <option>Curepipe</option>
                        </select>
                </div>

                <br></br>
                    <div className="box-form__car-time">
                        <label htmlFor="start-date">
                        Start Date <b>*</b>
                        </label>
                        <input
                            id="start-date"
                            name="start_date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            type="date"
                            min={new Date().toISOString().split("T")[0]} // Set minimum date to today
                            required
                            placeholder={validationError && validationError}
                        />
                    </div>

                    <br></br>
                    <div className="box-form__car-time">
                        <label htmlFor="end-date">
                        End Date <b>*</b>
                        </label>
                        <input
                            id="end-date"
                            name="end_date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            type="date"
                            min={new Date().toISOString().split("T")[0]} // Set minimum date to today
                            required
                            placeholder={validationError && validationError}
                        />
                    </div>

                    {validationError && <p className="error-message">{validationError}</p>}
                    
                    <br></br>
                    <div className="box-form__car-time">
                    <label>
                            No. of days <b>*</b>
                        </label>
                        <p className="reg-div_text" id="NumDays" name="NumDays">{numberOfDays !== null ? numberOfDays : "N/A"}</p>
        
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>


                    <button type="submit" className="submit-email">Confirm reservation</button>
                    <br></br>
                    <b style={{color: 'red', fontSize: 15, alignContent:'flex-end'}}> {validationError !== null ? validationError : "N/A"}</b>
                </form>
            </div>
            {/* </div> */}
            </div>
            </section>

            <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
    </>
  );
  
}

export default EmailJs;
