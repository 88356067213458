import { useState } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import ModalPrompt from './Modal_template'
// import ModalBookCar from "./ModalBookCar";
import EmailJs from "./EmailJs";

function CarBox({ data, carID, carmodel }) {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => {setIsModalOpen(true);};
  // const closeModal = () => {setIsModalOpen(false);};

  // console.log(car)

  const handleSubmit = (e) => {
    e.preventDefault();
    // openModal();

    const varmodel = document.getElementsById('model_picked');
    const text = varmodel.textContent;
    console.log('Element text:', varmodel);

  };

  const [carLoad, setCarLoad] = useState(true);
  return (
    <>
      <form className="box-form" onSubmit={handleSubmit} >
        {data[carID].map((car, id) => (
          <div key={id} className="box-cars">
            {/* car */}
            <div className="pick-car">
              {carLoad && <span className="loader"></span>}
              <img
                style={{ display: carLoad ? "none" : "block" }}
                src={car.img}
                alt="car_img"
                onLoad={() => setCarLoad(false)}
              />
            </div>
            {/* description */}
            <div className="pick-description">
              
              <div className="pick-description__price">
                <span>Description</span>
              </div>

              <div className="pick-description__table">
                <div className="pick-description__table__col">
                  <span>Mark</span>
                  <span>{car.mark}</span>
                </div>

                <div className="pick-description__table__col" id="model_picked">
                  <span>Model</span>
                  <span>{car.model}</span>
                </div>

                <div className="pick-description__table__col">
                  <span>Year</span>
                  <span>{car.year}</span>
                </div>

                <div className="pick-description__table__col">
                  <span>Doors</span>
                  <span>{car.doors}</span>
                </div>

                <div className="pick-description__table__col">
                  <span>Price/ Day</span>
                  <span>{car.price}</span>
                </div>

                <div className="pick-description__table__col">
                  <span>Transmission</span>
                  <span>{car.transmission}</span>
                </div>

                <div className="pick-description__table__col">
                  <span>Fuel</span>
                  <span>{car.fuel}</span>
                </div>

                <div className="pick-description__table__col">
                  <span>Minimum day</span>
                  <span>{car.minday}</span>
                </div>
              </div>
              <br></br>

              {/* <button type="submit" className="submit-email"> */}
              {/* <div className="hidden_div"> <EmailJs cardarw="Testliuiy"/> </div> */}
              
                <Link to={`/Booking?carmodel=${carmodel}`} className="submit-email" type="submit"> RESERVE NOW </Link>
                
                
              {/* </button> */}

              {/* <ModalBookCar isOpen={isModalOpen} onClose={closeModal} title={"Please complete this form..."} prompt={"An agent will get in touch"}/> */}

              {/* <modalSubmitForm isOpen={isModalOpen} onClose={closeModal} title={"Please complete this form..."} prompt={"An agent will get in touch"}/> */}


            </div>
          </div>
        ))}
      </form>

    </>
  );
}

export default CarBox;
