import React, { useState, useEffect } from "react";
import HeroPages from "../components/HeroPages";

function CarBookingForm(props) {
        const [dropOff, setDropOff] = useState("");
        const [startDate, setStartDate] = useState("");
        const [endDate, setEndDate] = useState("");
        const [numberOfDays, setNumberOfDays] = useState(null); // Store the calculated number of days
        const [validationError, setValidationError] = useState(""); // Store validation error message

        const handleDropOffChange = (e) => {
            setDropOff(e.target.value);
        };

        const handleStartDateChange = (e) => {
            setStartDate(e.target.value);
        };

        const handleEndDateChange = (e) => {
            setEndDate(e.target.value);
        };

        useEffect(() => {
            // Calculate the number of days when start date or end date changes
            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);
                const differenceInTime = end.getTime() - start.getTime();
                const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
                setNumberOfDays(differenceInDays);
            } else {
                setNumberOfDays(null); // Reset the number of days if dates are not set
            }
        }, [startDate, endDate]);

        const handleSubmit = (e) => {
            e.preventDefault();
            var err;
            var modelId = props.data;

            if (modelId <= 0 || modelId===null){
                err = "Please select a vehicle before proceeding."
                setValidationError(err);
                console.log(err)
                return; // Prevent form submission
            } 


            if (numberOfDays < 2) {
                err = "Minimum 2 days required between start and end dates."
                setValidationError(err);
                console.log(err)
                return; // Prevent form submission
            }

            // Validate that start and end dates are not before today's date
            const today = new Date();
            const selectedStartDate = new Date(startDate);
            const selectedEndDate = new Date(endDate);

            if (selectedStartDate < today || selectedEndDate < today) {
                err = "Start and end dates cannot be before or today's date."
                setValidationError(err);
                console.log(err)
                return; // Prevent form submission
            }

            // Clear any previous validation error
            setValidationError("");

            

            // Perform the POST request with the form data here
            const formData = {
                dropOff,
                startDate,
                endDate,
                numberOfDays,
                modelId

            };

            // Replace the following code with your actual POST request
            console.log("Form data for POST:", formData);
        };


  return (

    <>
    <HeroPages name="Sign Up" />
        <section id="booking-section" className="book-section">
        {/* overlay */}

        <div className="container">
            <div className="book-content">
            <div className="book-content__box">
                
                <form onSubmit={handleSubmit}>
                <h2>Vehicle selected: {props.data}</h2>
                    <div className="box-form__car-type">
                        <label>
                        Vehicle Drop-off Location <b>*</b>
                        </label>
                        <select value={dropOff} onChange={handleDropOffChange}>
                        <option>Select drop off location</option>
                        <option>Trou D'eau Douce</option>
                        <option>Flacq</option>
                        <option>Grand Gaube</option>
                        <option>Grand Bay</option>
                        <option>Curepipe</option>
                        </select>
                    </div>

                    <div className="box-form__car-time">
                        <label htmlFor="start-date">
                        Start Date <b>*</b>
                        </label>
                        <input
                        id="start-date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        type="date"
                        min={new Date().toISOString().split("T")[0]} // Set minimum date to today
                        required
                        placeholder={validationError && validationError}
                        />
                    </div>

                    <div className="box-form__car-time">
                        <label htmlFor="end-date">
                        End Date <b>*</b>
                        </label>
                        <input
                        id="end-date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        type="date"
                        min={new Date().toISOString().split("T")[0]} // Set minimum date to today
                        required
                        placeholder={validationError && validationError}
                        />
                    </div>

                    {validationError && <p className="error-message">{validationError}</p>}

                    <div className="box-form__car-time">
                        <label htmlFor="end-date">
                            Number of days: <b> {numberOfDays !== null ? numberOfDays : "N/A"}</b>
                        </label>
                    </div>


                    <button type="submit" className="submit-email">Confirm reservation</button>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <b style={{color: 'red', fontSize: 15, alignContent:'flex-end'}}> {validationError !== null ? validationError : "N/A"}</b>
                </form>
            </div>
            </div>
        </div>
        </section>

    </>



  );
}

export default CarBookingForm;
