import React, { useState , useEffect, useRef} from 'react';
import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import ChooseUs from "../components/ChooseUs";
// import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
// import Download from "../components/Download";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarBookingForm from "../components/CarBookingForm";

function Home() {
  useEffect(() => {window.scrollTo(0, 0);}, []);
  return (
    <>
      <HeroPages name="Home" />
      {/* <Hero /> */}
      <PlanTrip />
      <PickCar />
      {/* <CarBookingForm /> */}
      <ChooseUs />
      <Faq />
      <Footer />

      
      {/* <BookCar /> */}
      {/* <Testimonials /> */}
      {/* <Download /> */}
    </>
  );
}

export default Home;
