import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import BookCar from '../components/BookCar';
import CarBookingForm from '../components/CarBookingForm';

function Models() {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState("");

  useEffect(() => {
    fetch('http://localhost:3000/cars')
      .then(response => response.json())
      .then(items => setItems(items))
      .catch(error => console.error('Fetch failed:', error));
  }, []);


  const handleCheckboxChange = (itemId) => {
    setSelectedItem(itemId);
    setData(itemId);
    // alert("Good choice. Now confirm the information down below");
  };

  return (
    <>
    <HeroPages name="Contact" />
      <section className="models-section">
        <div className="container">
          <div className="models-div">
              {items.map(item => (
                <label key={item.id} >
                  <div  className="models-div__box">
                    <div className="models-div__box__img">
                       
                      <img src={item.img_path} alt={item.make + item.model}/>
                      
                      <div className="models-div__box__descr">

                        <div className="models-div__box__descr__name-price__name">
                        <p> 
                          <input type="checkbox" checked={selectedItem === item.id}
                            onChange={() => handleCheckboxChange(item.id)}
                            style={{ display: 'none' }} // Hide the checkbox
                          />
                          {item.make}  &nbsp; {item.model}
                        </p>

                          <span>
                            <i className="fa-solid fa-star">{item.price_usd} USD</i>
                            <i className="fa-solid fa-star">{item.price_eur} EUR</i>
                            <i className="fa-solid fa-star">{item.price_mur} MUR</i>
                          </span>
                        </div>

                        <table>
                          <tbody>
                              <tr className="models-div__box__descr__name-price__details">
                                <td>Year:  &nbsp; {item.year}</td>
                                <td>Class: {item.doors} Doors &nbsp; {item.class}</td>
                              </tr>

                              <tr className="models-div__box__descr__name-price__details">
                                <td>Engine:  &nbsp; {item.engine_size}cc</td>
                                <td>Fuel:  &nbsp; {item.fuel}</td>
                                <td>Trans:  &nbsp; {item.transmission}</td>
                                <td>Status:  &nbsp; {item.status}</td>
                              </tr>
                          </tbody>
                        </table>
                        
                        <div
                          className="submit-email"
                          style={{
                            borderColor: selectedItem === item.id ? 'green' : 'rgb(112, 56, 15)',
                            color:  selectedItem === item.id ? 'green' : 'white',
                            backgroundColor:  selectedItem === item.id ? 'white' : 'rgb(112, 56, 15)',
                          }}
                        >
                          {selectedItem === item.id ? 'SELECTED' : 'Book me!'}
                        </div> 

                      </div>
                          
                    </div>
                        
                  </div>
                </label>
              ))}
          </div>
        </div>
      </section>

      <CarBookingForm data={data} />

      
    </>
  );
}

export default Models;
