import jimny from "../images/cars-big/jimny.jpg";
import ertiga from "../images/cars-big/ertiga.jpg";
import spresso from "../images/cars-big/spresso.jpg";
import Bmw320 from "../images/cars-big/bmw320.jpg";
import Benz from "../images/cars-big/benz.jpg";
import Passat from "../images/cars-big/passatcc.jpg";

export const CAR_DATA = [
  [
    {
      name: "Suzuki Jimny",
      price: "Rs 2500",
      img: jimny,
      model: "JIMNY",
      mark: "Suzuki",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Auto",
      fuel: "Petrol",
      minday: "3",
    },
  ],


  [
    {
      name: "Suzuki Ertiga",
      price: "Rs 2500",
      img: ertiga,
      model: "Ertiga",
      mark: "Suzuki",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Auto",
      fuel: "Petrol",
      minday: "3",
    },
  ],


  [
    {
      name: "Suzuki S-Presso",
      price: "Rs 1700",
      img: spresso,
      model: "S-Presso",
      mark: "Suzuki",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Auto",
      fuel: "Petrol",
      minday: "3",
    },
  ],


  [
    {
      name: "BMW 320 ModernLine",
      price: "35",
      img: Bmw320,
      model: "320",
      mark: "BMW",
      year: "2012",
      doors: "4/5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],


  [
    {
      name: "Mercedes-Benz GLK",
      price: "50",
      img: Benz,
      model: "Benz GLK",
      mark: "Mercedes",
      year: "2006",
      doors: "4/5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],


  [
    {
      name: "VW Passat CC",
      price: "25",
      img: Passat,
      model: "Passat CC",
      mark: "Volkswagen",
      year: "2008",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],


];
