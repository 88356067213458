import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'   
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import ModalPrompt from './Modal_template'
import HeroPages from "../components/HeroPages";

function Login() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {setIsModalOpen(true);};
  const closeModal = () => {setIsModalOpen(false);};
    
  const navigate = useNavigate(); // Get the navigate function
  const [formData, setFormData] = useState({
      email: '',
      password: ''
    });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
    
  axios.defaults.withCredentials = true ;
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form login submitted:', formData);
    // Add your registration logic here (e.g., API calls, validation, etc.)
    axios.post('http://localhost:3000/login', formData)

    .then(res => {
        if(res.data.message === "Password matched"){
            console.log("Message: " + res.data.message);
            alert("Login successful!");
            navigate("/")
            //openModal();        
        }else{
            alert("Error with the credentials!");
            console.log("Message: " + res.data.message)
        }
      })

    // .then(err => console.log("ERROR" + err)); 
  };

  const handleReset = (e) => {
    e.preventDefault();
    console.log('Form Reset');
  };

  return (
    <>
    <HeroPages name="Sign In" />
        <section id="reg-section" className="reg-section">
            <div className="container">
              <div className="book-content">
                  <div className="book-content__box">
                    <h2>Login</h2>

                    <p className="error-message">
                        All fields required! <i className="fa-solid fa-xmark"></i>
                    </p>


                    <form className="box-form" onSubmit={handleSubmit} onReset={handleReset}>
                      {/* Email */}
                      <div className="box-form__car-time">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                          className="reg-div_text" 
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                      />
                      </div>



                      {/* Password */}
                      <div className="box-form__car-time">
                      <label htmlFor="password" className="form-label">Password</label>
                      <input
                          className="reg-div_text" 
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                      />
                      </div>


                      {/* Submit Button */}
                      <button type="submit">
                          Confirm
                      </button>

                      <ModalPrompt isOpen={isModalOpen} onClose={closeModal} title={"Success"} prompt={"Login Successful"}/>

                    </form>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <h2 className="">Don't have an account? <a href="Register">Register</a></h2>


                  </div>
              </div>
            </div>
        </section>
    </>
  )
}

export default Login;