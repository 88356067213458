function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>TDD</span>CAR Drive
              </li>
              <li>
                We offers a variety of vehicles for all your driving needs. We have the perfect car to meet your needs.
              </li>
              <li>
              <a href="tel:+23052548614">
                  <i className="fa-solid fa-phone"></i>(+230) 5254-8614
                </a>
              </li>

              <li>
                <a
                  href="mailto:tddcardrive@gmail.com"
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; tddcardrive@gmail.com
                </a>
              </li>


            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sun: 8:00AM - 5:00PM</li>
            </ul>

            <ul className="footer-content__2">
              <li>Designed by</li>
              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/kevicen/"
                >
                  Kevicen
                  Get in touch: https://www.linkedin.com/in/kevicen/
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
