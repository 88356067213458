import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import HeroPages from "../components/HeroPages";

function Register() {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: '',
      });
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log('Form register submitted: ', formData);
      // Add your registration logic here (e.g., API calls, validation, etc.)
      axios.post('http://localhost:3000/new', formData)
      .then(res => {
        if(res.data.message === "OK"){
          console.log("Message: " + res.data.message)
          alert("Account Created!");
        }
        
        else if(res.data.message === "User Exists"){
          alert("User already exists! Proceed to Login instead.");
          console.log("Message: " + res.data.message)
        }

        else if(res.data.message === "missing info"){
          alert("Missing some information, Check again!");
          console.log("Message: " + res.data.message)
        }

        else{
          alert("Something went wrong. Check your information!");
          console.log("NOK: " + res.data.message);
        }
        

      })
      
      // .then(err => console.log("ERROR" + err));
    };

  return (
    <>
    <HeroPages name="Sign Up" />
      <section id="reg-section" className="reg-section">
        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>Register new user</h2>

              <p className="error-message">
                All fields required! <i className="fa-solid fa-xmark"></i>
              </p>



              <form className="box-form" onSubmit={handleSubmit} >
                {/* Firstname */}
                  <div className="box-form__car-time">
                    <label htmlFor="name" className="form-label">First Name</label>
                    <input
                      className="reg-div_text" 
                      type="text"
                      name="firstname"
                      placeholder="E.g: John" 
                      value={formData.firstname}
                      onChange={handleChange}
                      required
                    />
                  </div>



                {/* Lastname */}
                <div className="box-form__car-time">
                  <label htmlFor="name" className="form-label">Last Name</label>
                  <input
                    className="reg-div_text" 
                    type="text"
                    name="lastname"
                    placeholder="E.g: Doe" 
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                  />
                </div>


                {/* Phone Number */}
                <div className="box-form__car-time">
                  <label htmlFor="phone" className="form-label">Phone Number</label>
                  
                  <input
                    className="reg-div_text" 
                    type="number"
                    name="phone"
                    placeholder="E.g: 123456.." 
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>



                {/* Email */}
                <div className="box-form__car-time">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    className="reg-div_text" 
                    type="email"
                    name="email"
                    placeholder="E.g: youremail@example.com" 
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>



                {/* Password */}
                <div className="box-form__car-time">
                  <label htmlFor="password" id="password" className="form-label">Password</label>
                  <input
                    className="reg-div_text" 
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>


                {/* Submit Button */}
                <button type="submit">
                    Confirm
                </button>

              </form>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <h2 className="">Already have an account? <a href="login">Login</a></h2>


            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Register;