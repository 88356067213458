import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Reusable Modal component
const ModalPrompt = ({ isOpen, onClose, title, prompt }) => {
  if (!isOpen) {
    return null;
  }

  const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  };


  const contentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
  };

  const buttonStyle = {
    backgroundColor: 'green',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
    width: '100%'
  };



  return (
    <>
        <div style={modalStyle}>
            <div style={contentStyle}>
            <h2>{title}</h2>
                <h1><p>{prompt}</p></h1>
            <button onClick={onClose} style={buttonStyle}>OK</button>
            </div>
        </div>
    
    </>



  );
};


export default ModalPrompt;